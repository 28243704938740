<template>
    <v-card flat>
        <v-card-title>
            <div class="d-flex flex-column">
                <div class="d-flex align-center">
                    <span class="secondary--text font-weight-bold">{{ headerTitle }}</span>
                    <v-btn icon @click="$store.dispatch('orgStore/fetchOrgSpaces', $route.params.oid)"><v-icon>refresh</v-icon></v-btn>
                </div>
                <span v-if="fetchingSpaces" class="caption text-uppercase">Fetching spaces data....</span>
                <span v-else-if="orgSpaceSortedByLongId.length" class="caption">{{ orgSpaceSortedByLongId.length }} spaces found</span>
            </div>
            <v-spacer></v-spacer>
            <v-btn
                v-if="(isOrgFaculty || isOrgManager) && spaceType === spaceTypes.EDUCATION_SPACE"
                @click="createSpaceAndApp('class')"
                text
                exact
                color="secondary"
                :disabled="fetchingSpaces">
                <v-icon small>add</v-icon>
                <span class="font-weight-bold">New Course</span>
            </v-btn>
            <v-btn
                v-if="(isOrgFaculty || isOrgManager) && spaceType === spaceTypes.RESEARCH_SPACE"
                @click="createSpaceAndApp('research')"
                text
                exact
                color="secondary"
                :disabled="fetchingSpaces">
                <v-icon small>add</v-icon>
                <span class="font-weight-bold">New Research Project</span>
            </v-btn>
            <v-btn
                :disabled="fetchingSpaces"
                v-if="(isOrgFaculty || isOrgManager) && spaceType === spaceTypes.VENDOR_SPACE"
                @click="createSpaceAndApp('dataset')"
                text
                exact
                color="secondary">
                <v-icon small>add</v-icon>
                <span class="font-weight-bold">New Dataset</span>
            </v-btn>
            <v-tooltip v-if="$route.name === 'home-dataset-spaces'" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        text
                        color="secondary"
                        v-on="on"
                        href="https://docs.nuvolos.cloud/features/database-integration#start-working-with-data"
                        target="_blank">
                        <v-icon small class="mr-1">menu_book</v-icon>
                        <span class="font-weight-bold">work with datasets</span>
                    </v-btn>
                </template>
                <span>Read the documentation on how to work with datasets in Nuvolos</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <v-container v-if="orgSpaceSortedByLongId.length && !fetchingSpaces" fluid grid-list-md>
                <v-text-field autofocus :label="searchLabel" v-model="searchSpace" outlined></v-text-field>
                <v-data-iterator
                    :items="orgSpaceSortedByLongId"
                    :items-per-page.sync="itemsPerPage"
                    :footer-props="{ itemsPerPageOptions }"
                    :search="searchSpace"
                    :hide-default-footer="hideFooter">
                    <template v-slot:default="props">
                        <div v-for="(item, index) in props.items" :key="item.sid">
                            <v-divider inset v-if="index > 0" style="opacity: 0.25; border-width: thin" class="mb-2" />
                            <v-list-item two-line>
                                <v-list-item-avatar>
                                    <v-icon x-large>{{ spaceIcon }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <router-link
                                            :to="{
                                                name: 'snapshot-overview',
                                                params: { oid: $route.params.oid, sid: item.sid, iid: item.iid, snid: item.snid }
                                            }"
                                            style="text-decoration: none">
                                            <v-hover v-slot:default="{ hover }">
                                                <span :class="[hover ? 'secondary--text' : 'secondary--text', 'mr-2', 'subtitle-1', 'font-weight-bold']">
                                                    {{ item.space_long_id }}
                                                </span>
                                            </v-hover>
                                        </router-link>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-tooltip bottom max-width="700">
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on" class="caption">{{ item.space_description }}</span>
                                            </template>
                                            <span>{{ item.space_description }}</span>
                                        </v-tooltip>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </template>
                    <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning" text class="mt-3">Your search for "{{ searchSpace }}" found no results.</v-alert>
                    </template>
                </v-data-iterator>
            </v-container>
            <div v-else-if="!orgSpaceSortedByLongId.length">
                <v-banner v-if="!fetchingSpaces" two-line>
                    <v-avatar slot="icon" color="white" size="60">
                        <v-icon x-large icon="info" color="info">info</v-icon>
                    </v-avatar>
                    <div class="d-flex flex-column secondary--text">
                        <span class="font-weight-bold">
                            <span v-if="spaceType === spaceTypes.EDUCATION_SPACE">No courses found</span>
                            <span v-if="spaceType === spaceTypes.RESEARCH_SPACE">No projects found</span>
                            <span v-if="spaceType === spaceTypes.VENDOR_SPACE">No datasets found</span>
                        </span>
                        <span class="secondary--text" v-if="spaceType === spaceTypes.EDUCATION_SPACE">
                            Currently you don't have any courses set up in this organization.
                        </span>
                        <span class="secondary--text" v-if="spaceType === spaceTypes.RESEARCH_SPACE">
                            Currently you don't have any research projects set up in this organization.
                        </span>
                        <span v-if="spaceType === spaceTypes.VENDOR_SPACE" class="secondary--text">
                            Currently this organization has no activated datasets. Please reach out to your organization manager to onboard a dataset plan.
                        </span>
                    </div>
                    <template v-slot:actions>
                        <div v-if="(isOrgFaculty || isOrgManager) && spaceType !== 'Dataset'">
                            <v-btn small color="info" v-if="spaceType === spaceTypes.EDUCATION_SPACE" outlined text @click="createSpaceAndApp('class')">
                                <v-icon small>add</v-icon>
                                new course
                            </v-btn>
                            <v-tooltip v-if="spaceType === spaceTypes.EDUCATION_SPACE" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        small
                                        color="info"
                                        text
                                        v-on="on"
                                        href="https://docs.nuvolos.cloud/getting-started/quickstart-tutorials/education-instructor#1.-add-a-new-course"
                                        target="_blank">
                                        <span>documentation</span>
                                    </v-btn>
                                </template>
                                <span>Read the documentation on how to create a course</span>
                            </v-tooltip>
                            <v-btn small outlined text color="info" v-if="spaceType === spaceTypes.RESEARCH_SPACE" @click="createSpaceAndApp('research')">
                                <v-icon small>add</v-icon>
                                new research project
                            </v-btn>
                            <v-tooltip v-if="spaceType === spaceTypes.RESEARCH_SPACE" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        small
                                        color="info"
                                        text
                                        v-on="on"
                                        href="https://docs.nuvolos.cloud/getting-started/quickstart-tutorials/research"
                                        target="_blank">
                                        <span>documentation</span>
                                    </v-btn>
                                </template>
                                <span>Read the documentation on how to create a research project</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-banner>
            </div>
            <div v-else-if="fetchingSpaces">
                <div
                    v-for="item in [
                        { id: 'c1', opacity: 1 },
                        { id: 'c2', opacity: 0.75 },
                        { id: 'c3', opacity: 0.5 }
                    ]"
                    :key="item.id">
                    <div :style="{ opacity: item.opacity }">
                        <v-skeleton-loader type="list-item-three-line" class="mx-auto" />
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { sortArray, truncateText } from '@/utils'
import { enumsData } from '@/mixins/enums'

export default {
    name: 'SpaceDataList',
    mixins: [enumsData],
    data() {
        return {
            searchSpace: '',
            itemsPerPage: 100,
            itemsPerPageOptions: [-1, 10, 50, 100]
        }
    },
    props: {
        spaceData: Array,
        spaceType: Number,
        spaceIcon: String,
        hideFooter: { type: Boolean, default: false },
        headerTitle: String,
        showSearchIcon: { type: Boolean, default: true }
    },
    methods: {
        createSpaceAndApp(type) {
            this.$store.dispatch('homeStore/updateCreateSpaceAndApp', {
                operationSelectedVal: true,
                stepVal: 1,
                spaceTypeVal: type
            })
            if (type === 'class') {
                this.$router.push({
                    name: 'home-add-class',
                    params: { oid: this.$route.params.oid }
                })
            } else if (type === 'research') {
                this.$router.push({
                    name: 'home-add-research-project',
                    params: { oid: this.$route.params.oid }
                })
            } else if (type === 'dataset') {
                this.$router.push({
                    name: 'home-add-dataset',
                    params: { oid: this.$route.params.oid }
                })
            }
        },
        truncateText: function (text, length) {
            return truncateText(text, length)
        }
    },
    computed: {
        ...mapGetters('orgStore', ['isOrgFaculty', 'isOrgManager']),
        ...mapState('orgStore', ['fetchingSpaces']),
        searchLabel() {
            if (this.$props.spaceType === this.spaceTypes.EDUCATION_SPACE) {
                return 'Search for a course..'
            } else if (this.$props.spaceType === this.spaceTypes.RESEARCH_SPACE) {
                return 'Search for a research project..'
            } else if (this.$props.spaceType === this.spaceTypes.VENDOR_SPACE) {
                return 'Search for a dataset..'
            } else {
                return 'Search for a space...'
            }
        },
        orgSpaceSortedByLongId() {
            if (this.$props.spaceData) {
                const sortedSpaceNames = sortArray(this.$props.spaceData, 'space_long_id', 'ascending', true)
                return sortedSpaceNames
            } else {
                return []
            }
        }
    }
}
</script>
